/**
 * © 2020 Infosys Limited, Bangalore, India. All Rights Reserved.
 *
 * Infosys believes the information in this document is accurate as of its publication date; such
 * information is subject to change without notice. Infosys acknowledges the proprietary rights of
 * other companies to the trademarks, product names and such other intellectual property rights
 * mentioned in this document. Except as expressly permitted, neither this documentation nor any
 * part of it may be reproduced, stored in a retrieval system, or transmitted in any form or by any
 * means, electronic, mechanical, printing, photocopying, recording or otherwise, without the prior
 * permission of Infosys Limited and/or any named intellectual property rights holders under this
 * document.
 *
 */

// @nebular theming framework
@import "@nebular/theme/styles/theming";
// @nebular out of the box themes
@import "@nebular/theme/styles/themes";

$nb-enable-css-custom-properties: true;
$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-border-radius: 7px,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    card-header-text-font-size: 0.9rem,
    select-min-width: 6rem,
    error-msg-color: red,
    ng5-slider-selected-color: #1ba97f,
    tick-color: #00b28f,
    option-selected-color: white,
    nb-toggle-button-color: #46b590,
    insights-body-color: solid#80808054,
    slide-out-background: #f7f9fc,
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    header-height: 4.2rem,
    chat-bubble-color: #e3f5f4,
    chat-bubble-position-bottom: 3vh,
    chat-bubble-width: 6rem,
    chat-bubble-height: 6rem,
    chat-window-position-bottom: 5vh,
    tag-color: #e3f5f4,
    footer-text-font-size: 0.8rem,
    danger-color: #ff4c61,
    footer-padding: 0 2.25rem,
    primary-color-tb: #007cc3,
    progress-bar-bg-color: #dddddd,
    trainer-info-text-color: grey,
    score-success-color: rgba(176, 230, 221, 100%),
    score-danger-color: rgba(253, 199, 208, 100%),
    gauge-negative-color: #ff4c91,
    gauge-positive-color: #00b28f,
    font-family-error: "system-ui, -apple-system, OpenSans, HelveticaNeue, sans-serif",
    gauge-neutral-color: #df9926,
    gauge-yellow-color: #fff615,
    checkbox-info-checked-checkmark-color: white,
    transcript-text-color: #000000,
    nb-stepper-color: white,
    code-block-bg-color: #f2f2f5,
    is-loader-shade: hsla(0, 0%, 99.2%, 0.7),
    footer-color: #fcfcff,
    sign-out-color: white,
    path-fill-empower-color: #bbbbbb,
    code-block-text-color: #333333,
    double-multiply-color: #fff,
    double-multiply-bg-color: #00d4f1,
    bg-color: #737373,
    code-text-color: #333333,
    nb-progress-bar-progress-value: #945999,
    selected-item-color: #00b28f29,
    card-bg-color: #fcfcff,
    primary-color-courses: primary-color,
    courses-heading: #181818,
    transcript-agent-text-colors: #333333,
    transcript-client-text-colors: #333333,
    entity-and-level-one: #93278f,
    language-query-level-two: #4d52a3,
    language-query-level-two-via: #007cc3,
    composite-regex-table: #999,
    res-color-intent-and-resumeIntentResponse: #3cb44b,
    res-color-spell-check: #ffe119,
    res-color-fallback: #e6194b,
    res-color-liveagent: #800000,
    res-color-fallback-search-engine: #f58231,
    res-color-small-talk: #a9a9a9,
    res-color-document-comprehension: #9a6324,
    res-color-faq: #fabebe,
    res-color-questionnaire: #4363d8,
    entity-yellow: #eade07,
    entity-blue: #06b9ea,
    entity-red: #f9a141,
    progress-bar-purple-color: #945999,
    icon-color-invertion: invert(1),
    entity-green: #56d803,
    legend-negative-color: rgba(255, 76, 97, 0.5),
    legend-neutral-color: rgba(223, 153, 38, 0.5),
    legend-positive-color: rgba(25, 179, 102, 0.5),
    batch-table-header-bg-color: #3cb44b33,
    batch-table-odd-row-bg-color: #4363d833
  ),
  default,
  default
);

$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-header-text-font-size: 0.9rem,
    route-tabset-tab-text-transform: none,
    route-tabset-tab-padding: 0.7rem 2.5rem 0.5rem 1rem,
    route-tabset-tab-text-font-weight: 500,
    route-tabset-tab-background-color: color-basic-700,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: #252547,
    sidebar-shadow: none,
    footer-shadow: none,
    error-msg-color: red,
    ng5-slider-selected-color: #1ba97f,
    option-selected-color: white,
    nb-toggle-button-color: #46b590,
    level-bar-basic-color: #cccccc,
    insights-body-color: solid#80808054,
    slide-out-shadow-color: 2px 0 3px #29157a,
    slide-out-shadow-color-rtl: -2px 0 3px #29157a,
    button-text-font-weight: bold,
    link-text-decoration: none,
    card-header-text-color: color-primary-400,
    button-filled-text-transform: none,
    button-outline-text-transform: none,
    button-filled-small-padding: 0.2rem 0.8rem,
    button-outline-small-padding: 0.2rem 0.8rem,
    button-filled-medium-padding: 0.6rem 1rem,
    button-outline-medium-padding: 0.6rem 1rem,
    button-filled-danger-text-color: white,
    button-filled-warning-text-color: white,
    route-tabset-divider-color: #cccccc,
    card-divider-width: 1px,
    card-header-margin: 0.7rem 1rem 0,
    card-padding: 1rem,
    primary-color: text-primary-color,
    primary-text-color: #baa4c7,
    secondary-text-color: #c0b8b8,
    text-basic-color: #dfdbdb,
    nb-progress-bar-progress-value: #945999,
    navbar-text-color: secondary-text-color,
    tabset-tab-text-transform: none,
    header-height: 4.2rem,
    success-color: #00b28f,
    danger-color: #ff4c61,
    chart-purple: #945999,
    tick-color: #00b28f,
    chart-yellow: #df9926,
    chart-pink: #b9467c,
    header-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
    text-button-font-weight: 400,
    card-shadow: 0 4px 5px 0 rgba(116, 114, 114, 0.12),
    card-border-radius: 7px,
    alternate-card-shadow: 0 0 12px 0 rgba(124, 124, 124, 0.12),
    tabset-tab-active-underline-color: secondary-text-color,
    route-tabset-tab-active-background-color: color-basic-900,
    route-tabset-tab-active-text-color: color-basic-400,
    route-tabset-tab-hover-background-color: color-basic-800,
    route-tabset-tab-hover-text-color: color-basic-300,
    route-tabset-tab-active-underline-color: color-basic-200,
    sidebar-width-compact: 4.3rem,
    home-page-svgs-color: #cccccc,
    chat-bubble-color: #9e70f7,
    chat-bubble-position-bottom: 3vh,
    chat-bubble-width: 6rem,
    chat-bubble-height: 6rem,
    chat-window-position-bottom: 5vh,
    tag-color: #9e70f7,
    tags-text-color: white,
    footer-text-font-size: 0.8rem,
    footer-padding: 0 2.25rem,
    font-family-primary: "OpenSansRegular , sans-serif",
    font-family-secondary: "OpenSansBold , sans-serif",
    text-paragraph-line-height: 1.1rem,
    primary-color-tb: text-primary-color,
    button-filled-primary-disabled-background-color: #57466e,
    button-filled-primary-disabled-border-color: #57466e,
    button-filled-primary-disabled-text-color: #e0e0e2d0,
    button-outline-primary-disabled-border-color: #57466e,
    button-outline-primary-disabled-text-color: #e0e0e2d0,
    filter-input-table: #323259,
    card-panel-header-background: text-primary-color,
    card-label: text-basic-color,
    agent-icon-color: #9e70f7,
    smart-table-filter-color: #f5f5f5,
    progress-bar-bg-color: #dddddd,
    menu-text-color: #222b45,
    menu-item-hover-background-color: #222b45,
    menu-submenu-item-hover-background-color: #222b45,
    menu-item-hover-text-color: white,
    menu-item-divider-style: none,
    menu-item-padding: 0.3rem 1rem,
    menu-submenu-padding: 0.3rem 1rem,
    menu-background-color: white,
    breadcrumb-passed-color: secondary-text-color,
    gauge-labels: secondary-text-color,
    trainer-info-text-color: #dddddd,
    step-text-color: secondary-text-color,
    reviewed-icon: #45bd62,
    not-reviewed-icon: #888989,
    score-success-color: rgba(52, 108, 66, 100%),
    score-danger-color: rgba(148, 62, 62, 100%),
    table-text-color: white,
    gauge-negative-color: #b94646,
    gauge-positive-color: #45bd62,
    gauge-neutral-color: #cfb359,
    gauge-yellow-color: #e8e24f,
    negatives: #b94646,
    positive: #45bd62,
    neutral: #cfb359,
    toastr-success-background-color: success-color,
    toastr-success-border-color: success-color,
    toastr-success-text-color: white,
    toastr-icon-success-background-color: success-color,
    toastr-destroyable-hover-success-background-color: success-color,
    toastr-destroyable-hover-success-border-color: success-color,
    toastr-icon-success-color: white,
    toastr-primary-background-color: primary-color,
    toastr-primary-border-color: primary-color,
    toastr-primary-text-color: white,
    toastr-icon-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-border-color: primary-color,
    toastr-icon-primary-color: white,
    toastr-danger-background-color: danger-color,
    toastr-danger-border-color: danger-color,
    toastr-danger-text-color: white,
    toastr-icon-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-border-color: danger-color,
    toastr-icon-danger-color: white,
    toastr-warning-background-color: chart-yellow,
    toastr-warning-border-color: chart-yellow,
    toastr-warning-text-color: white,
    toastr-icon-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-border-color: chart-yellow,
    toastr-icon-warning-color: white,
    transcript-text-color: white,
    nb-stepper-color: white,
    font-family-error: "system-ui, -apple-system, OpenSans, HelveticaNeue, sans-serif",
    skeleton-background-color: color-basic-600,
    chart-background-color: layout-background-color,
    code-block-bg-color: #252547,
    code-block-text-color: #dfdbdb,
    is-loader-shade: hsla(241deg 23% 41% / 70%),
    footer-color: #fcfcff,
    sign-out-color: white,
    path-fill-empower-color: #bbbbbb,
    code-text-color: #333333,
    double-multiply-color: #fff,
    double-multiply-bg-color: #00d4f1,
    bg-color: #737373,
    selected-item-color: #00b28f29,
    card-bg-color: #fcfcff,
    icon-color-invert: invert(1),
    primary-color-courses: primary-color,
    // Learn Redesign screen Theme changes
    custom-theme-white-color: white,
    retry-button-color: white,
    next-prev-button-background: #a16eff,
    selected-clip-background: #a16eff,
    mic-record-bg: #a16eff,
    inner-circle-bg: #95deff,
    outer-circle-bg: #a16eff,
    retry-button-background: #a16eff,
    button-color: #a16eff,
    customer-transcript-background: #45bd62,
    hint-background: selected-clip-background,
    custom-blue-theme-heading: custom-theme-white-color,
    custom-blue-theme-text-color: white,
    custom-blue-theme-agent-text-color: black,
    custom-blue-theme-customer-text-color: white,
    custom-blue-theme-heading-text-color: custom-theme-white-color,
    course-description-color: white,
    custom-blue-theme-label-color: white,
    result-card-heading: white,
    agent-transcript-background: white,
    learn-stepper-bg: selected-clip-background,
    learn-stepper-color: white,
    learn-stepper-border: white,
    courses-heading: white,
    transcript-agent-text-colors: #333333,
    transcript-client-text-colors: #f5f5f5,
    custom-blue-theme-agent-transcript: black,
    card-divider-color: #fcfcff,
    courses-text-color: #fcfcff,
    view-all-text-color: #fcfcff,
    course-info-color: #fcfcff,
    custom-blue-background: #252547,
    info-head: white,
    inner-card-outline: white,
    grammar-text-color: black,
    agent-trans-text-colour: black,
    info-card-colors: button-filled-primary-background-color,
    sentiment-neutral: #faff17,
    sentiment-positive: #01ff78,
    play-icon-color: next-prev-button-background,
    entity-and-level-one: #93278f,
    language-query-level-two: #4d52a3,
    language-query-level-two-via: #007cc3,
    composite-regex-table: #999,
    icon-color-invertion: invert(0),
    res-color-intent-and-resumeIntentResponse: #3cb44b,
    res-color-spell-check: #ffe119,
    res-color-fallback: #e6194b,
    res-color-liveagent: #800000,
    res-color-fallback-search-engine: #f58231,
    res-color-small-talk: #a9a9a9,
    res-color-document-comprehension: #9a6324,
    res-color-faq: #fabebe,
    res-color-questionnaire: #4363d8,
    entity-yellow: #eade07,
    entity-blue: #06b9ea,
    entity-red: #f9a141,
    entity-green: #56d803,
    progress-bar-purple-color: #945999,
    icon-invert-color: invert(1),
    legend-negative-color: rgba(255, 76, 97, 0.5),
    legend-neutral-color: rgba(223, 153, 38, 0.5),
    legend-positive-color: rgba(25, 179, 102, 0.5),
    batch-table-header-bg-color: #3cb44b33,
    batch-table-odd-row-bg-color: #4363d833
  ),
  cosmic,
  cosmic
);

$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-text-font-size: 0.9rem,
    insights-body-color: solid#80808054,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    route-tabset-tab-text-transform: none,
    route-tabset-tab-padding: 0.7rem 2.5rem 0.5rem 1rem,
    route-tabset-tab-text-font-weight: 500,
    button-text-font-weight: bold,
    link-text-decoration: none,
    primary-color: #007cc3,
    error-msg-color: red,
    ng5-slider-selected-color: #1ba97f,
    option-selected-color: white,
    nb-toggle-button-color: #46b590,
    level-bar-basic-color: #cccccc,
    primary-text-color: #fcfcff,
    secondary-text-color: #333333,
    text-basic-color: #2e2a2a,
    sidebar-shadow: none,
    footer-shadow: none,
    sidebar-background-color: primary-text-color,
    sidebar-width-compact: 4.3rem,
    sidebar-text-color: secondary-text-color,
    card-header-text-color: color-primary-400,
    button-filled-text-transform: none,
    button-outline-text-transform: none,
    button-filled-small-padding: 0.2rem 0.8rem,
    button-outline-small-padding: 0.2rem 0.8rem,
    button-filled-medium-padding: 0.6rem 1rem,
    button-outline-medium-padding: 0.6rem 1rem,
    route-tabset-divider-color: #cccccc,
    card-divider-width: 1px,
    card-header-margin: 0.7rem 1rem 0,
    card-padding: 1rem,
    card-border-radius: 7px,
    header-height: 4.2rem,
    header-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
    text-button-font-weight: 400,
    card-shadow: 0 4px 5px 0 rgba(116, 114, 114, 0.12),
    alternate-card-shadow: 0 0 12px 0 rgba(124, 124, 124, 0.12),
    tabset-tab-active-underline-color: secondary-text-color,
    tabset-tab-text-transform: none,
    route-tabset-background-color: primary-text-color,
    route-tabset-tab-text-color: secondary-text-color,
    route-tabset-tab-active-background-color: primary-color,
    route-tabset-tab-active-text-color: primary-text-color,
    route-tabset-tab-hover-background-color: primary-color,
    route-tabset-tab-hover-text-color: primary-text-color,
    route-tabset-tab-active-underline-color: primary-color,
    route-tabset-tab-background-color: primary-text-color,
    home-page-svgs-color: #cccccc,
    chat-bubble-color: #e3f5f4,
    chat-bubble-position-bottom: 3vh,
    chat-bubble-width: 6rem,
    chat-bubble-height: 6rem,
    chat-window-position-bottom: 5vh,
    tag-color: #e3f5f4,
    success-color: #00b28f,
    tick-color: #00b28f,
    danger-color: #ff4c61,
    chart-purple: #945999,
    chart-yellow: #df9926,
    chart-pink: #e15999,
    breadcrumb-passed-color: secondary-text-color,
    footer-text-font-size: 0.8rem,
    footer-padding: 0 2.25rem,
    font-family-primary: "OpenSansRegular , sans-serif",
    font-family-secondary: "OpenSansBold , sans-serif",
    text-paragraph-line-height: 1.1rem,
    primary-color-tb: #007cc3,
    button-filled-primary-disabled-background-color: #8eaaff,
    button-filled-primary-disabled-border-color: #8eaaff,
    button-filled-primary-disabled-text-color: #fcfcff,
    button-outline-primary-disabled-background-color: #fcfcff,
    button-outline-primary-disabled-border-color: #8eaaff,
    button-outline-primary-disabled-text-color: #8eaaff,
    button-filled-danger-text-color: black,
    button-filled-warning-text-color: white,
    agent-icon-color: rgb(0, 178, 143),
    navbar-text-color: secondary-text-color,
    card-panel-header-background: #007cc3,
    card-label: text-basic-color,
    smart-table-filter-color: text-basic-color,
    progress-bar-bg-color: #dddddd,
    menu-text-color: #222b45,
    menu-item-hover-background-color: #222b45,
    menu-submenu-item-hover-background-color: #222b45,
    menu-item-hover-text-color: white,
    menu-item-divider-style: none,
    menu-item-padding: 0.3rem 1rem,
    menu-submenu-padding: 0.3rem 1rem,
    menu-background-color: white,
    gauge-labels: secondary-text-color,
    trainer-info-text-color: grey,
    step-text-color: #696969,
    reviewed-icon: #00b28f,
    not-reviewed-icon: #e5e5e8,
    score-success-color: rgba(176, 230, 221, 100%),
    score-danger-color: rgba(253, 199, 208, 100%),
    table-text-color: #333333,
    gauge-negative-color: #ff4c91,
    gauge-positive-color: #00b28f,
    gauge-neutral-color: #df9926,
    gauge-yellow-color: #fff615,
    negatives: #ff4c61,
    positive: #00b28f,
    neutral: #df9926,
    toastr-success-background-color: success-color,
    toastr-success-border-color: success-color,
    toastr-success-text-color: white,
    toastr-icon-success-background-color: success-color,
    toastr-destroyable-hover-success-background-color: success-color,
    toastr-destroyable-hover-success-border-color: success-color,
    toastr-icon-success-color: white,
    toastr-primary-background-color: primary-color,
    toastr-primary-border-color: primary-color,
    toastr-primary-text-color: white,
    toastr-icon-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-border-color: primary-color,
    toastr-icon-primary-color: white,
    toastr-danger-background-color: danger-color,
    toastr-danger-border-color: danger-color,
    toastr-danger-text-color: white,
    toastr-icon-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-border-color: danger-color,
    toastr-icon-danger-color: white,
    toastr-warning-background-color: chart-yellow,
    toastr-warning-border-color: chart-yellow,
    toastr-warning-text-color: white,
    toastr-icon-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-border-color: chart-yellow,
    toastr-icon-warning-color: white,
    transcript-text-color: #000000,
    nb-stepper-color: white,
    skeleton-background-color: color-basic-400,
    chart-background-color: card-background-color,
    code-block-bg-color: #f2f2f5,
    code-block-text-color: #2e2a2a,
    is-loader-shade: hsla(0, 0%, 99.2%, 0.7),
    footer-color: #737373,
    sign-out-color: white,
    path-fill-empower-color: #bbbbbb,
    code-text-color: #333333,
    double-multiply-color: #fff,
    double-multiply-bg-color: #00d4f1,
    bg-color: #737373,
    nb-progress-bar-progress-value: #945999,
    footer-background-color: #fcfcff,
    font-family-error: "system-ui, -apple-system, OpenSans, HelveticaNeue, sans-serif",
    selected-item-color: #00b28f29,
    card-bg-color: #fcfcff,
    icon-color-invert: invert(0),
    primary-color-courses: primary-color,
    //Learn Redesign screen changes for Themes
    custom-theme-white-color: #737373,
    custom-blue-background: card-bg-color,
    next-prev-button-background: lightblue,
    selected-clip-background: lightblue,
    mic-record-bg: lightblue,
    inner-circle-bg: #95deff,
    outer-circle-bg: lightblue,
    retry-button-background: lightblue,
    button-color: lightblue,
    result-card-background: card-bg-color,
    customer-transcript-background: #737373,
    hint-background: selected-clip-background,
    custom-blue-theme-heading: custom-theme-white-color,
    custom-blue-theme-text-color: #333333,
    custom-blue-theme-agent-text-color: black,
    custom-blue-theme-customer-text-color: white,
    custom-blue-theme-heading-text-color: custom-theme-white-color,
    course-description-color: black,
    custom-blue-theme-label-color: #333333,
    result-card-heading: #333333,
    agent-transcript-background: #cae7e4,
    learn-stepper-bg: selected-clip-background,
    learn-stepper-color: white,
    learn-stepper-border: #333333,
    courses-heading: black,
    custom-blue-theme-agent-transcript: white,
    button-filled-primary-background-color: lightblue,
    card-divider-color: #333333,
    courses-text-color: #333333,
    view-all-text-color: #333333,
    transcript-agent-text-colors: #333333,
    transcript-client-text-colors: #333333,
    course-info-color: black,
    info-head: black,
    inner-card-outline: black,
    grammar-text-color: white,
    agent-trans-text-colour: black,
    info-card-colors: button-filled-primary-background-color,
    sentiment-neutral: #faff17,
    sentiment-positive: #01ff78,
    play-icon-color: next-prev-button-background,
    entity-and-level-one: #93278f,
    language-query-level-two: #4d52a3,
    language-query-level-two-via: #007cc3,
    composite-regex-table: #999,
    res-color-intent-and-resumeIntentResponse: #3cb44b,
    res-color-spell-check: #ffe119,
    res-color-fallback: #e6194b,
    res-color-liveagent: #800000,
    res-color-fallback-search-engine: #f58231,
    res-color-small-talk: #a9a9a9,
    res-color-document-comprehension: #9a6324,
    res-color-faq: #fabebe,
    res-color-questionnaire: #4363d8,
    entity-yellow: #eade07,
    entity-blue: #06b9ea,
    entity-red: #f9a141,
    progress-bar-purple-color: #945999,
    icon-color-invertion: invert(1),
    entity-green: #56d803,
    legend-negative-color: rgba(255, 76, 97, 0.5),
    legend-neutral-color: rgba(223, 153, 38, 0.5),
    legend-positive-color: rgba(25, 179, 102, 0.5),
    batch-table-header-bg-color: #3cb44b33,
    batch-table-odd-row-bg-color: #4363d833
  ),
  corporate,
  corporate
);

$nb-themes: nb-register-theme(
  (
    layout-padding-top: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    select-min-width: 6rem,
    color-basic-100: #f4f0ee,
    color-basic-200: #e9e1de,
    color-basic-300: #beb3b0,
    color-basic-400: #7e7473,
    color-basic-500: #9b9b9b,
    color-basic-600: #686868,
    color-basic-700: #212020,
    color-basic-800: #171616,
    color-basic-900: #181717,
    color-basic-1000: #111111,
    color-basic-1100: #000000,
    error-msg-color: red,
    ng5-slider-selected-color: #1ba97f,
    option-selected-color: white,
    nb-toggle-button-color: #46b590,
    level-bar-basic-color: #cccccc,
    // layout-background-color: #18191a,
    header-background-color: #222425,
    footer-background-color: #222425,
    card-background-color: #242526,
    header-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2),
    primary-color: #4bc7ff,
    primary-text-color: #e7e7e7,
    secondary-text-color: #f5f5f5,
    text-basic-color: #f5f5f5,
    sidebar-background-color: color-basic-700,
    sidebar-width-compact: 4.3rem,
    sidebar-text-color: secondary-text-color,
    slide-out-background: linear-gradient(270deg, #222b45 0%, #151a30 100%),
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    header-height: 4.2rem,
    route-tabset-tab-text-transform: none,
    route-tabset-tab-padding: 0.7rem 2.5rem 0.5rem 1rem,
    route-tabset-tab-text-font-weight: 500,
    button-text-font-weight: bold,
    link-text-decoration: none,
    card-header-text-color: #e7e7e7,
    button-filled-text-transform: none,
    button-outline-text-transform: none,
    button-filled-small-padding: 0.2rem 0.8rem,
    button-outline-small-padding: 0.2rem 0.8rem,
    button-filled-medium-padding: 0.6rem 1rem,
    button-outline-medium-padding: 0.6rem 1rem,
    route-tabset-divider-color: #f5f5f5,
    card-divider-width: 1px,
    card-divider-color: #474747,
    card-header-margin: 0.7rem 1rem 0,
    card-border-radius: 7px,
    card-padding: 1rem,
    tabset-tab-text-transform: none,
    sidebar-shadow: none,
    footer-shadow: none,
    text-button-font-weight: 400,
    card-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12),
    card-header-text-font-size: 0.9rem,
    insights-body-color: solid#80808054,
    alternate-card-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12),
    home-page-svgs-color: primary-text-color,
    tags-text-color: primary-text-color,
    tag-color: #333333,
    chat-bubble-color: #333333,
    chat-bubble-position-bottom: 3vh,
    chat-bubble-width: 6rem,
    chat-bubble-height: 6rem,
    chat-window-position-bottom: 5vh,
    footer-text-font-size: 0.8rem,
    footer-padding: 0 2.25rem,
    font-family-primary: "OpenSansRegular , sans-serif",
    font-family-secondary: "OpenSansBold , sans-serif",
    text-paragraph-line-height: 1.1rem,
    primary-color-tb: primary-color,
    success-color: #45bd62,
    tick-color: #00b28f,
    danger-color: #b94646,
    chart-purple: #964bb4,
    chart-yellow: #cfb359,
    chart-pink: #b9467c,
    button-filled-primary-disabled-background-color: #62cbfc81,
    button-filled-primary-disabled-border-color: #62cbfc81,
    button-filled-primary-disabled-text-color: #3a3a3ab0,
    button-outline-primary-disabled-border-color: #62cbfc81,
    button-outline-primary-disabled-text-color: #62cbfc81,
    button-outline-primary-hover-background-color: #47474794,
    buton-outline-primary-hover-text-color: #4bc6ffdc,
    button-outline-primary-hover-border-color: #4bc6ffd3,
    navbar-text-color: primary-text-color,
    icon-primary-color: primary-color,
    footer-text-highlight-color: secondary-text-color,
    filter-input-table: #171616,
    card-panel-header-background: #171616,
    agent-icon-color: rgb(69, 189, 98),
    tabset-tab-active-underline-color: secondary-text-color,
    button-filled-primary-active-background-color: primary-color,
    button-filled-primary-active-border-color: primary-color,
    button-filled-primary-background-color: primary-color,
    button-filled-primary-border-color: primary-color,
    button-filled-primary-text-color: #242526,
    button-filled-primary-focus-background-color: primary-color,
    button-filled-primary-focus-border-color: primary-color,
    button-filled-danger-text-color: white,
    button-filled-warning-text-color: white,
    button-outline-primary-active-background-color: #242526,
    button-outline-primary-active-border-color: primary-color,
    button-outline-primary-background-color: #242526,
    button-outline-primary-border-color: primary-color,
    button-outline-primary-text-color: primary-color,
    button-outline-primary-focus-background-color: #242526,
    button-outline-primary-focus-border-color: primary-color,
    route-tabset-background-color: #242526,
    route-tabset-tab-text-color: secondary-text-color,
    route-tabset-tab-active-background-color: primary-color,
    route-tabset-tab-active-text-color: #242526,
    route-tabset-tab-hover-background-color: primary-color,
    route-tabset-tab-hover-text-color: #242526,
    route-tabset-tab-active-underline-color: primary-color,
    color-primary-hover: primary-color,
    option-selected-background-color: primary-color,
    option-selected-text-color: primary-text-color,
    option-selected-hover-background-color: primary-color,
    option-selected-hover-text-color: primary-text-color,
    progress-bar-danger-filled-background-color: primary-text-color,
    progress-bar-primary-filled-background-color: primary-color,
    popover-background-color: danger-color,
    progress-bar-warning-filled-background-color: danger-color,
    toastr-success-background-color: success-color,
    toastr-success-border-color: success-color,
    toastr-success-text-color: white,
    toastr-icon-success-background-color: success-color,
    toastr-destroyable-hover-success-background-color: success-color,
    toastr-destroyable-hover-success-border-color: success-color,
    toastr-icon-success-color: white,
    toastr-primary-background-color: primary-color,
    toastr-primary-border-color: primary-color,
    toastr-primary-text-color: white,
    toastr-icon-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-border-color: primary-color,
    toastr-icon-primary-color: white,
    toastr-danger-background-color: danger-color,
    toastr-danger-border-color: danger-color,
    toastr-danger-text-color: white,
    toastr-icon-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-border-color: danger-color,
    toastr-icon-danger-color: white,
    toastr-warning-background-color: chart-yellow,
    toastr-warning-border-color: chart-yellow,
    toastr-warning-text-color: white,
    toastr-icon-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-border-color: chart-yellow,
    toastr-icon-warning-color: white,
    stepper-connector-completed-background-color: success-color,
    stepper-step-index-completed-border-color: success-color,
    stepper-step-index-completed-background-color: success-color,
    stepper-step-completed-text-color: success-color,
    card-label: text-basic-color,
    card-border-color: none,
    smart-table-filter-color: secondary-text-color,
    progress-bar-bg-color: #dddddd,
    menu-text-color: #222b45,
    menu-item-hover-background-color: #222b45,
    menu-submenu-item-hover-background-color: #222b45,
    menu-item-hover-text-color: white,
    menu-item-divider-style: none,
    menu-item-padding: 0.3rem 1rem,
    menu-submenu-padding: 0.3rem 1rem,
    menu-background-color: white,
    breadcrumb-passed-color: secondary-text-color,
    gauge-labels: secondary-text-color,
    trainer-info-text-color: #dddddd,
    step-text-color: #c0c0c0,
    reviewed-icon: #45bd62,
    not-reviewed-icon: #888989,
    tag-chip-border: #000000,
    score-success-color: rgba(52, 108, 66, 100%),
    score-danger-color: rgba(148, 62, 62, 100%),
    table-text-color: white,
    gauge-negative-color: #b94646,
    gauge-positive-color: #45bd62,
    gauge-neutral-color: #cfb359,
    gauge-yellow-color: #e8e24f,
    negatives: #b94646,
    positive: #45bd62,
    neutral: #cfb359,
    transcript-text-color: white,
    nb-stepper-color: white,
    skeleton-background-color: rgb(50, 50, 50),
    chart-background-color: layout-background-color,
    code-block-bg-color: #18191a,
    code-block-text-color: #f5f5f5,
    is-loader-shade: hsla(0deg 2% 25% / 70%),
    footer-color: #fcfcff,
    sign-out-color: white,
    path-fill-empower-color: #bbbbbb,
    code-text-color: #333333,
    double-multiply-color: #fff,
    double-multiply-bg-color: #00d4f1,
    bg-color: #737373,
    icon-color-invert: invert(1),
    nb-progress-bar-progress-value: #945999,
    font-family-error: "system-ui, -apple-system, OpenSans, HelveticaNeue, sans-serif",
    //custom-blue-theme for Dark Theme
    card-bg-color: #fcfcff,
    custom-theme-white-color: white,
    retry-button-color: #fcfcff,
    custom-blue-background: card-background-color,
    next-prev-button-background: button-filled-primary-background-color,
    selected-clip-background: #00b28f29,
    mic-record-bg: #4bc7ff,
    inner-circle-bg: #95deff,
    outer-circle-bg: #4bc7ff,
    retry-button-background: button-filled-primary-background-color,
    button-color: button-filled-primary-background-color,
    result-card-background: #242526,
    customer-transcript-background: #45bd62,
    hint-background: selected-clip-background,
    custom-blue-theme-heading: custom-theme-white-color,
    custom-blue-theme-text-color: white,
    custom-blue-theme-agent-text-color: black,
    custom-blue-theme-heading-text-color: custom-theme-white-color,
    course-description-color: white,
    custom-blue-theme-label-color: white,
    result-card-heading: white,
    agent-transcript-background: white,
    learn-stepper-bg: selected-clip-background,
    learn-stepper-color: white,
    learn-stepper-border: white,
    primary-color-courses: primary-color,
    att-agent-transcript: black,
    transcript-agent-text-colors: #333333,
    transcript-client-text-colors: #f5f5f5,
    courses-text-color: #fcfcff,
    view-all-text-color: #fcfcff,
    course-info-color: #fcfcff,
    info-head: white,
    icon-color-invertion: invert(0),
    inner-card-outline: white,
    grammar-text-color: black,
    agent-trans-text-colour: black,
    custom-blue-theme-customer-text-color: white,
    info-card-colors: button-filled-primary-background-color,
    sentiment-neutral: #faff17,
    sentiment-positive: #01ff78,
    play-icon-color: next-prev-button-background,
    entity-and-level-one: #93278f,
    language-query-level-two: #4d52a3,
    language-query-level-two-via: #007cc3,
    composite-regex-table: #999,
    res-color-intent-and-resumeIntentResponse: #3cb44b,
    res-color-spell-check: #ffe119,
    res-color-fallback: #e6194b,
    res-color-liveagent: #800000,
    res-color-fallback-search-engine: #f58231,
    res-color-small-talk: #a9a9a9,
    res-color-document-comprehension: #9a6324,
    res-color-faq: #fabebe,
    res-color-questionnaire: #4363d8,
    entity-yellow: #eade07,
    entity-blue: #06b9ea,
    entity-red: #f9a141,
    entity-green: #56d803,
    progress-bar-purple-color: #945999,
    icon-invert-color: invert(1),
    legend-negative-color: rgba(255, 76, 97, 0.5),
    legend-neutral-color: rgba(223, 153, 38, 0.5),
    legend-positive-color: rgba(25, 179, 102, 0.5),
    batch-table-header-bg-color: #3cb44b33,
    batch-table-odd-row-bg-color: #4363d833
  ),
  dark,
  dark
);

$nb-themes: nb-register-theme(
  (
    layout-padding: 2.25rem,
    menu-item-icon-margin: 0 0.5rem 0 0,
    card-height-tiny: 13.5rem,
    card-height-small: 21.1875rem,
    card-height-medium: 28.875rem,
    card-height-large: 36.5625rem,
    card-height-giant: 44.25rem,
    card-margin-bottom: 1.875rem,
    card-header-with-select-padding-top: 0.5rem,
    card-header-with-select-padding-bottom: 0.5rem,
    card-header-text-font-size: 0.9rem,
    insights-body-color: solid#80808054,
    select-min-width: 6rem,
    slide-out-background: #f7f9fc,
    slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
    slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3,
    primary-color: #007cc3,
    primary-text-color: #fcfcff,
    secondary-text-color: #333333,
    text-basic-color: #737373,
    smart-table-filter-color: secondary-text-color,
    background-basic-color-1: primary-text-color,
    header-height: 4.2rem,
    header-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2),
    sidebar-background-color: secondary-text-color,
    sidebar-width-compact: 4.3rem,
    sidebar-text-color: primary-text-color,
    background-basic-color-3: #f2f2f5,
    sidebar-shadow: none,
    footer-shadow: none,
    error-msg-color: red,
    ng5-slider-selected-color: #1ba97f,
    option-selected-color: white,
    nb-toggle-button-color: #46b590,
    level-bar-basic-color: #cccccc,
    color-primary-hover: primary-color,
    text-button-font-weight: 400,
    tabset-tab-active-underline-color: secondary-text-color,
    tabset-tab-text-transform: none,
    route-tabset-background-color: primary-text-color,
    route-tabset-tab-text-color: secondary-text-color,
    route-tabset-tab-active-background-color: primary-color,
    route-tabset-tab-active-text-color: primary-text-color,
    route-tabset-tab-hover-background-color: primary-color,
    route-tabset-tab-hover-text-color: primary-text-color,
    route-tabset-tab-active-underline-color: primary-color,
    route-tabset-tab-text-transform: none,
    route-tabset-tab-padding: 0.7rem 2.5rem 0.5rem 1rem,
    route-tabset-tab-text-font-weight: 500,
    route-tabset-tab-background-color: primary-text-color,
    route-tabset-divider-color: #e5e5e8,
    card-divider-width: 1px,
    card-header-margin: 0.7rem 1rem 0,
    card-header-text-color: primary-color,
    card-padding: 1rem,
    card-border-radius: 7px,
    card-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12),
    alternate-card-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12),
    button-filled-primary-active-background-color: primary-color,
    button-filled-primary-active-border-color: primary-color,
    button-filled-primary-background-color: primary-color,
    button-filled-primary-border-color: primary-color,
    button-filled-primary-text-color: primary-text-color,
    button-filled-primary-focus-background-color: primary-color,
    button-filled-primary-focus-border-color: primary-color,
    button-outline-primary-active-background-color: primary-text-color,
    button-outline-primary-active-border-color: primary-color,
    button-outline-primary-background-color: primary-text-color,
    button-outline-primary-border-color: primary-color,
    button-outline-primary-text-color: primary-color,
    button-outline-primary-focus-background-color: primary-text-color,
    button-outline-primary-focus-border-color: primary-color,
    button-text-font-weight: bold,
    link-text-decoration: none,
    button-filled-text-transform: none,
    button-outline-text-transform: none,
    button-filled-small-padding: 0.2rem 0.8rem,
    button-outline-small-padding: 0.2rem 0.8rem,
    button-filled-medium-padding: 0.6rem 1rem,
    button-outline-medium-padding: 0.6rem 1rem,
    home-page-svgs-color: #cccccc,
    success-color: #00b28f,
    toastr-success-background-color: success-color,
    toastr-success-border-color: success-color,
    toastr-success-text-color: white,
    toastr-icon-success-background-color: success-color,
    toastr-destroyable-hover-success-background-color: success-color,
    toastr-destroyable-hover-success-border-color: success-color,
    toastr-icon-success-color: white,
    toastr-primary-background-color: primary-color,
    toastr-primary-border-color: primary-color,
    toastr-primary-text-color: white,
    toastr-icon-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-border-color: primary-color,
    toastr-icon-primary-color: white,
    toastr-danger-background-color: danger-color,
    toastr-danger-border-color: danger-color,
    toastr-danger-text-color: white,
    toastr-icon-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-border-color: danger-color,
    toastr-icon-danger-color: white,
    toastr-warning-background-color: chart-yellow,
    toastr-warning-border-color: chart-yellow,
    toastr-warning-text-color: white,
    toastr-icon-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-border-color: chart-yellow,
    toastr-icon-warning-color: white,
    chat-bubble-color: #e3f5f4,
    chat-bubble-position-bottom: 3vh,
    chat-bubble-width: 6rem,
    chat-bubble-height: 6rem,
    chat-window-position-bottom: 5vh,
    tag-color: #e3f5f4,
    stepper-connector-background-color: #f2f2f5,
    stepper-step-index-border-color: #cccccc,
    stepper-connector-completed-background-color: success-color,
    stepper-step-index-completed-border-color: success-color,
    stepper-step-index-completed-background-color: success-color,
    stepper-step-completed-text-color: success-color,
    accordion-header-text-color: primary-color,
    header-text-color: black,
    footer-background-color: #737373,
    footer-text-color: primary-text-color,
    footer-text-highlight-color: primary-text-color,
    footer-text-font-size: 0.8rem,
    footer-padding: 0 2.25rem,
    font-family-primary: "OpenSansRegular , sans-serif",
    font-family-secondary: "OpenSansBold , sans-serif",
    text-paragraph-line-height: 1.1rem,
    primary-color-tb: #007cc3,
    danger-color: #ff4c61,
    chart-purple: #945999,
    chart-yellow: #df9926,
    chart-pink: #e15999,
    option-selected-background-color: primary-color,
    option-selected-text-color: primary-text-color,
    option-selected-hover-background-color: primary-color,
    option-selected-hover-text-color: primary-text-color,
    popover-text-color: white,
    popover-background-color: danger-color,
    button-filled-primary-disabled-background-color: #5e7bd3c5,
    button-filled-primary-disabled-border-color: #5e7bd3c5,
    button-filled-primary-disabled-text-color: #dddddd,
    button-filled-danger-text-color: white,
    button-filled-warning-text-color: white,
    button-outline-primary-disabled-background-color: #dddddd,
    button-outline-primary-disabled-border-color: #5e7bd3c5,
    button-outline-primary-disabled-text-color: #5e7bd3c5,
    progress-bar-danger-filled-background-color: primary-text-color,
    progress-bar-primary-filled-background-color: primary-color,
    agent-icon-color: rgb(0, 178, 143),
    navbar-text-color: secondary-text-color,
    progress-bar-warning-filled-background-color: danger-color,
    card-panel-header-background: primary-color,
    card-label: text-basic-color,
    progress-bar-bg-color: #dddddd,
    menu-text-color: #222b45,
    menu-item-hover-background-color: #222b45,
    menu-submenu-item-hover-background-color: #222b45,
    menu-item-hover-text-color: white,
    menu-item-divider-style: none,
    menu-item-padding: 0.3rem 1rem,
    menu-submenu-padding: 0.3rem 1rem,
    menu-background-color: white,
    breadcrumb-passed-color: secondary-text-color,
    gauge-labels: secondary-text-color,
    trainer-info-text-color: grey,
    step-text-color: #696969,
    reviewed-icon: #00b28f,
    not-reviewed-icon: #e5e5e8,
    color-basic-100: #000000,
    tag-chip-border: #696969,
    score-success-color: rgba(176, 230, 221, 100%),
    score-danger-color: rgba(253, 199, 208, 100%),
    table-text-color: #333333,
    gauge-negative-color: #ff4c91,
    gauge-positive-color: #00b28f,
    gauge-neutral-color: #df9926,
    gauge-yellow-color: #fff615,
    negatives: #ff4c61,
    positive: #00b28f,
    neutral: #df9926,
    font-family-error: "system-ui, -apple-system, OpenSans, HelveticaNeue, sans-serif",
    tooltip-background-color: white,
    // tabset-tab-active-underline-color: secondary-text-color,
    tabset-tab-focus-underline-color: secondary-text-color,
    tabset-tab-hover-underline-color: secondary-text-color,
    transcript-text-color: #000000,
    nb-stepper-color: white,
    skeleton-background-color: color-basic-400,
    chart-background-color: card-background-color,
    code-block-bg-color: #f2f2f5,
    code-block-text-color: #333333,
    text-alternate-color: #fff,
    is-loader-shade: hsla(0, 0%, 99.2%, 0.7),
    footer-color: #fcfcff,
    sign-out-color: white,
    path-fill-empower-color: #bbbbbb,
    card-style-color: #00b28f,
    tick-color: #00b28f,
    double-multiply-color: #fff,
    double-multiply-bg-color: #00d4f1,
    bg-color: #737373,
    code-text-color: #333333,
    selected-item-color: #00b28f29,
    card-bg-color: #fcfcff,
    icon-color-invert: invert(0),
    custom-theme-white-color: white,
    custom-blue-background: #fcfcff,
    next-prev-button-background: button-filled-primary-background-color,
    selected-clip-background: #00b28f29,
    mic-record-bg: #4bc7ff,
    inner-circle-bg: #95deff,
    outer-circle-bg: #4bc7ff,
    retry-button-background: button-filled-primary-background-color,
    button-color: button-filled-primary-background-color,
    // result-card-background: #242526,
    customer-transcript-background: #ffe4c4,
    hint-background: selected-clip-background,
    custom-blue-theme-text-color: black,
    course-description-color: black,
    custom-blue-theme-label-color: black,
    custom-blue-theme-heading-text-color: black,
    custom-blue-theme-agent-text-color: #333333,
    result-card-heading: black,
    result-card-background: white,
    agent-transcript-background: #daf3fb,
    learn-stepper-bg: primary-color,
    learn-stepper-color: white,
    learn-stepper-border: stepper-step-index-border-color,
    courses-heading: black,
    primary-color-courses: primary-color,
    custom-blue-theme-customer-text-color: #333333,
    sentiment-positive: #01ff78,
    sentiment-neutral: #faff17,
    untouched-bg-color: #cccccc,
    att-agent-transcript: black,
    nb-progress-bar-progress-value: #945999,
    transcript-agent-text-colors: #333333,
    transcript-client-text-colors: #333333,
    retry-button-color: #fcfcff,
    courses-text-color: #333333,
    view-all-text-color: #333333,
    card-divider-color: #333333,
    slider-and-result-bg-color: black,
    course-info-color: black,
    info-head: black,
    custom-blue-theme-agent-transcript: #333333,
    inner-card-outline: black,
    grammar-text-color: black,
    agent-trans-text-colour: black,
    info-card-colors: button-filled-primary-background-color,
    play-icon-color: next-prev-button-background,
    nb-route-tabset-color: white,
    entity-and-level-one: #93278f,
    language-query-level-two: #4d52a3,
    language-query-level-two-via: #007cc3,
    composite-regex-table: #999,
    res-color-intent-and-resumeIntentResponse: #3cb44b,
    res-color-spell-check: #ffe119,
    res-color-fallback: #e6194b,
    res-color-liveagent: #800000,
    res-color-fallback-search-engine: #f58231,
    res-color-small-talk: #a9a9a9,
    res-color-document-comprehension: #9a6324,
    res-color-faq: #fabebe,
    res-color-questionnaire: #4363d8,
    entity-yellow: #eade07,
    entity-blue: #06b9ea,
    entity-red: #f9a141,
    entity-green: #56d803,
    progress-bar-purple-color: #945999,
    icon-invert-color: invert(0),
    legend-negative-color: rgba(255, 76, 97, 0.5),
    legend-neutral-color: rgba(223, 153, 38, 0.5),
    legend-positive-color: rgba(25, 179, 102, 0.5),
    batch-table-header-bg-color: #3cb44b33,
    batch-table-odd-row-bg-color: #4363d833
  ),
  light,
  default
);

$nb-themes: nb-register-theme(
  (
    custom-blue-background: #162f86,
    success-color: #00b28f,
    nb-stepper-color: white,
    custom-theme-white-color: white,
    custom-blue-theme-background-dark-blue: #000036,
    button-color: linear-gradient(45deg, #3478ff, #e642f7),
    selected-clip-background: #631882,
    hint-background: #7725ac,
    customer-transcript-background: #f8e7ff,
    next-prev-button-background: linear-gradient(90deg, #3478ff, #e642f7),
    scrollbar-bgcolor: lightgray,
    retry-button-background: linear-gradient(45deg, #3478ff, #e642f7),
    record-circle-bg: linear-gradient(135deg, #f7f7f700, #ffffff52, #f9f9f900),
    inner-circle-bg: #35cbf9,
    inner-card-bg: #d1d1ec,
    mic-record-bg: linear-gradient(120deg, #3478ff, #ff3bf6),
    outer-circle-bg: rgb(53, 203, 249, 0.4),
    danger-color: red,
    result-card-background: #4d1e83,
    gauge-neutral-color: #df9926,
    gauge-positive-color: #00b28f,
    gauge-negative-color: #ff4c91,
    progress-bar-bg-color: #dddddd,
    slider-color: white,
    slider-and-result-bg-color: black,
    sentiment-positive: #01ff78,
    sentiment-neutral: #faff17,
    chart-yellow: #df9926,
    untouched-bg-color: #cccccc,
    custom-theme-primary-color: #007cc3,
    font-family-primary: "OpenSansRegular , sans-serif",
    font-family-secondary: "OpenSansBold , sans-serif",
    button-filled-text-transform: none,
    custom-blue-theme-heading-text-color: custom-theme-white-color,
    custom-blue-theme-text-color: white,
    course-description-color: white,
    custom-blue-theme-label-color: white,
    result-card-heading: white,
    agent-transcript-background: white,
    learn-stepper-bg: purple,
    learn-stepper-color: white,
    learn-stepper-border: white,
    //Whole screen styling
    layout-background-color: #000036,
    toastr-success-background-color: success-color,
    toastr-success-border-color: success-color,
    toastr-success-text-color: white,
    toastr-icon-success-background-color: success-color,
    toastr-destroyable-hover-success-background-color: success-color,
    toastr-destroyable-hover-success-border-color: success-color,
    toastr-icon-success-color: white,
    toastr-primary-background-color: primary-color,
    toastr-primary-border-color: primary-color,
    toastr-primary-text-color: white,
    toastr-icon-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-background-color: primary-color,
    toastr-destroyable-hover-primary-border-color: primary-color,
    toastr-icon-primary-color: white,
    toastr-danger-background-color: danger-color,
    toastr-danger-border-color: danger-color,
    toastr-danger-text-color: white,
    toastr-icon-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-background-color: danger-color,
    toastr-destroyable-hover-danger-border-color: danger-color,
    toastr-icon-danger-color: white,
    toastr-warning-background-color: chart-yellow,
    toastr-warning-border-color: chart-yellow,
    toastr-warning-text-color: white,
    toastr-icon-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-background-color: chart-yellow,
    toastr-destroyable-hover-warning-border-color: chart-yellow,
    toastr-icon-warning-color: white,

    primary-color: #73e2ff,
    card-background-color: #162f86,
    button-filled-primary-background-color: #631882,
    button-filled-primary-border-color: #631882,
    button-outline-primary-background-color: #631882,
    button-outline-primary-text-color: white,
    footer-background-color: #222425,
    card-text-color: white,
    table-text-color: #fcfcff,
    sidebar-background-color: #212020,
    sidebar-text-color: white,
    text-basic-color: white,
    courses-heading: white,
    primary-color-courses: #077669,
    custom-blue-theme-agent-text-color: #333333,
    custom-blue-theme-customer-text-color: #333333,
    nb-route-tabset-color: #162f86,
    smart-table-header-bg: #162f86,
    smart-table-bg-active: #162f86,
    smart-table-bg-even: #162f86,
    chat-bubble-color: #0d6b66,
    tag-color: #096f69,
    tags-text-color: white,
    accordion-item-background-color: #030341,
    header-background-color: #162f86,
    dataColor-color: #737373,
    selected-color: #737373,
    nb-progress-bar-progress-value: #631882,
    retry-button-color: #fcfcff,
    secondary-text-color: #fcfcff,
    courses-text-color: #333333,
    view-all-text-color: #fcfcff,
    custom-blue-theme-agent-transcript: #333333,
    card-header-margin: 0.7rem 1rem 0,
    chart-purple: #945999,
    chart-pink: #e15999,
    select-outline-basic-background-color: #162f86,
    option-background-color: #162f86,
    input-basic-text-color: #333333,
    input-primary-text-color: #333333,
    select-outline-basic-focus-background-color: #162f86,
    course-info-color: white,
    info-head: white,
    icon-color-invert: invert(0),
    inner-card-outline: white,
    grammar-text-color: black,
    agent-trans-text-colour: black,
    info-card-colors: White,
    play-icon-color: #a859c0,
    entity-and-level-one: #93278f,
    language-query-level-two: #4d52a3,
    language-query-level-two-via: #007cc3,
    composite-regex-table: #999,
    res-color-intent-and-resumeIntentResponse: #3cb44b,
    res-color-spell-check: #ffe119,
    res-color-fallback: #e6194b,
    res-color-liveagent: #800000,
    res-color-fallback-search-engine: #f58231,
    res-color-small-talk: #a9a9a9,
    res-color-document-comprehension: #9a6324,
    res-color-faq: #fabebe,
    res-color-questionnaire: #4363d8,
    entity-yellow: #eade07,
    entity-blue: #06b9ea,
    entity-red: #f9a141,
    progress-bar-purple-color: #945999,
    entity-green: #56d803,
    legend-negative-color: rgba(255, 76, 97, 0.5),
    legend-neutral-color: rgba(223, 153, 38, 0.5),
    legend-positive-color: rgba(25, 179, 102, 0.5),
    batch-table-header-bg-color: #3cb44b33,
    batch-table-odd-row-bg-color: #4363d833
  ),
  custom-blue,
  default
);
